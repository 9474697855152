<template>
  <div class="bg-gray-100 px-4 py-5 border-b border-gray-200 mb-0 mt-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Device Level Reports
    </h3>
    <p class="mt-1 text-sm text-gray-500">
      Reports for individual devices
    </p>
  </div>

  <div
    class="rounded-lg bg-gray-100 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px"
  >
    <div
      v-for="(report, reportIdx) in device_level_reports"
      :key="report.title"
      :class="[
        reportIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
        reportIdx === 1 ? 'sm:rounded-tr-lg' : '',
        reportIdx === device_level_reports.length - 2 ? 'sm:rounded-bl-lg' : '',
        reportIdx === device_level_reports.length - 1
          ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
          : '',
        'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
      ]"
    >
      <div>
        <span
          :class="[
            report.iconBackground,
            report.iconForeground,
            'rounded-lg inline-flex p-3 ring-4 ring-white',
          ]"
        >
          <component :is="report.icon" class="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <router-link :to="report.href" class="focus:outline-none">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true" />
            {{ report.title }}
          </router-link>
        </h3>
        <p class="mt-2 text-sm text-gray-500">
          {{ report.description }}
        </p>
      </div>
      <span
        class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
        aria-hidden="true"
      >
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
          />
        </svg>
      </span>
    </div>
  </div>

  <div
    class="bg-gray-100 px-4 py-5 border-b border-gray-200 mb-0 mt-10 sm:px-6"
  >
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Gateway Level Reports
    </h3>
    <p class="mt-1 text-sm text-gray-500">
      Reports combining multiple devices associated with the same gateway
    </p>
  </div>

  <div
    class="rounded-lg bg-gray-100 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px"
  >
    <div
      v-for="(report, reportIdx) in gateway_level_reports"
      :key="report.title"
      :class="[
        reportIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
        reportIdx === 1 ? 'sm:rounded-tr-lg' : '',
        reportIdx === gateway_level_reports.length - 2
          ? 'sm:rounded-bl-lg'
          : '',
        reportIdx === gateway_level_reports.length - 1
          ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
          : '',
        'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
      ]"
    >
      <div>
        <span
          :class="[
            report.iconBackground,
            report.iconForeground,
            'rounded-lg inline-flex p-3 ring-4 ring-white',
          ]"
        >
          <component :is="report.icon" class="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <router-link :to="report.href" class="focus:outline-none">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true" />
            {{ report.title }}
          </router-link>
        </h3>
        <p class="mt-2 text-sm text-gray-500">
          {{ report.description }}
        </p>
      </div>
      <span
        class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
        aria-hidden="true"
      >
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import {
  ChartBarIcon,
  LightningBoltIcon,
  GlobeAltIcon,
  UserGroupIcon,
  ShoppingCartIcon,
  SparklesIcon,
  DownloadIcon,
  LinkIcon,
  AdjustmentsIcon,
} from '@heroicons/vue/outline';

const device_level_reports = [
  {
    title: 'World Map',
    href: '/reports/mapReport',
    icon: GlobeAltIcon,
    description: 'Global view of Devices and Gateways',
    iconForeground: 'text-green-700',
    iconBackground: 'bg-green-50',
  },
  // {
  //   title: 'Platform Summary Report',
  //   href: '/reports/platformSummaryReport',
  //   icon: AdjustmentsIcon,
  //   description: 'Summary of all Devices, Gateways and Alerts on the Platform',
  //   iconForeground: 'text-red-700',
  //   iconBackground: 'bg-red-50',
  // },
  {
    title: 'Temperature',
    href: '/reports/temperatureReport',
    icon: ChartBarIcon,
    description: 'Temperature charts across your fleet of devices',
    iconForeground: 'text-blue-700',
    iconBackground: 'bg-blue-50',
  },
  {
    title: 'Traffic',
    href: '/reports/trafficReport',
    icon: UserGroupIcon,
    description: 'Volume of activity in front of your traffic sensors',
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    title: 'Door Openings',
    href: '/reports/doorReport',
    icon: ShoppingCartIcon,
    description: 'Number of door openings per sensor',
    iconForeground: 'text-green-700',
    iconBackground: 'bg-green-50',
  },
  {
    title: 'Battery Life',
    href: '/reports/batteryReport',
    icon: LightningBoltIcon,
    description: 'Healthy batteries maintain voltage above 2.9v',
    iconForeground: 'text-red-700',
    iconBackground: 'bg-red-50',
  },
  {
    title: 'Humidity',
    href: '/reports/humidityReport',
    icon: LightningBoltIcon,
    description: 'Temperature and Humidity across the fleet',
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
  },
  {
    title: 'Air Quality',
    href: '/reports/airQualityReport',
    icon: SparklesIcon,
    description: 'Effective CO2 and Total Volatile Organic Compound levels',
    iconForeground: 'text-blue-700',
    iconBackground: 'bg-blue-50',
  },

  // {
  //   title: 'Conversion Metrics',
  //   href: '/reports/conversionReport',
  //   icon: PresentationChartLineIcon,
  //   description: 'Door Openings divided by Traffic',
  //   iconForeground: 'text-yellow-700',
  //   iconBackground: 'bg-yellow-50',
  // },
];

const gateway_level_reports = [
  {
    title: 'Traffic Vs Door Openings',
    href: '/reports/trafficVersusDoorReport',
    icon: LinkIcon,
    description: 'Gateway level report for traffic converstion',
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
  },
  {
    title: 'Temperature Vs Door Openings',
    href: '/reports/temperatureVersusDoorReport',
    icon: LinkIcon,
    description: 'Gateway level report for door impact on temperatures',
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
  },
  {
    title: 'Data Downloads',
    href: '/reports/dataReport',
    icon: DownloadIcon,
    description: 'Export data to CSV format',
    iconForeground: 'text-blue-700',
    iconBackground: 'bg-blue-50',
  },
];

export default {
  setup() {
    return {
      device_level_reports,
      gateway_level_reports,
    };
  },
};
</script>
