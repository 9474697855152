import { createApp } from 'vue';
import { version } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import './index.css';
import './style.css';
import store from './store.js';
import VueApexCharts from 'vue3-apexcharts';
import authConfig from '../auth_config.json';
import { setupAuth } from './auth';

axios.defaults.baseURL = process.env.VUE_APP_CORETHINGS_API;
console.log('axios.defaults.baseURL:' + axios.defaults.baseURL);
console.log('VUE VERSION: ');
console.log(version);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueApexCharts);

function callbackRedirect(appState) {
  router.push(appState && appState.targetUrl ? appState.targetUrl : '/');
}

app.config.productionTip = false;

setupAuth(authConfig, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app');
});

// app.mount('#app');
