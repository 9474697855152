<template>
  <div v-if="loading" class="flex overflow-hidden bg-gray-100 justify-center">
    <div><loading-widget /></div>
  </div>
  <div class="text-center mt-20" v-if="no_results">
    <h3 class="mt-2 text-sm font-medium text-gray-900">No Results</h3>
    <p class="mt-1 text-sm text-gray-500">
      Get started by creating a new gateway.
    </p>
    <div class="mt-6">
      <button
        type="button"
        v-on:click="openScan"
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        New Gateway
      </button>
    </div>
  </div>
  <div class="flex justify-end mr-5 mt-2 mb-2">
    <Menu as="div" class="relative inline-block self-end text-left">
      <div>
        <MenuButton
          class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          Timeframe
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="z-40 origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="py-1">
            <MenuItem v-slot="{ active }">
              <a
                v-on:click="loadGraphs('1h')"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                >Last 1 hour</a
              >
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                v-on:click="loadGraphs('6h')"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                >Last 6 hours</a
              >
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                v-on:click="loadGraphs('1d')"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                >Last 1 day</a
              >
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                v-on:click="loadGraphs('3d')"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                >Last 3 days</a
              >
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                v-on:click="loadGraphs('7d')"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                >Last 7 days</a
              >
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>

  <div v-for="chart in dataset" :key="chart.core_gateway_id">
    <div>
      <apexchart
        width="100%"
        height="300"
        :options="chart.options"
        :series="chart.series"
      ></apexchart>
    </div>
  </div>
  <server-response :response="serverResponse"></server-response>
</template>

<script>
import axios from 'axios';
import ServerResponse from '../../components/ServerResponse';
import LoadingWidget from '../../components/LoadingWidget.vue';
import { PlusIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';

export default {
  components: {
    ServerResponse,
    LoadingWidget,
    PlusIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  data() {
    return {
      core_gateway_ids: [],
      dataset: [],
      loading: false,
      date: new Date(),
      no_results: false,
    };
  },
  methods: {
    openScan() {
      this.$router.push('/scan');
    },

    loadGraphs(timeframe) {
      this.loading = true;

      //Let's clear the dataset first
      this.dataset = [];

      //Let's get the data for each node for this customer
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;
      axios
        .get(
          '/gateways?core_account_name=' +
            currentAccountSession +
            '&core_project_name=' +
            currentProjectSession
        )
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          let results = res.data.data;
          let counter = 0;

          if (results.length == 0) {
            this.loading = false;
            this.no_results = true;
          }

          for (let i = 0; i < results.length; i++) {
            axios
              .get(
                '/reports/gateway?measure_name=aisle_count&report_type=incremental&&opposite_y_axis=door_open&core_gateway_id=' +
                  results[i].core_gateway_id +
                  '&timeframe=' +
                  timeframe
              )
              .then((res) => {
                let set = {
                  core_gateway_id: results[i].core_gateway_id,
                  options: {
                    theme: {
                      palette: 'palette2',
                    },

                    chart: {
                      stacked: false,
                      type: 'area',
                      height: 350,
                      zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                      },
                      toolbar: {
                        autoSelected: 'zoom',
                      },
                    },
                    stroke: {
                      show: true,
                      curve: ['stepline', 'stepline'],
                      lineCap: 'butt',
                      colors: undefined,
                      width: [1, 0.5],
                      dashArray: 0,
                    },
                    fill: {
                      colors: undefined,
                      opacity: [0.5, 0.1],
                      type: 'gradient',
                      gradient: {
                        shade: 'light',
                        type: 'vertical',
                        shadeIntensity: 1,
                        gradientToColors: undefined,
                        inverseColors: true,
                        opacityFrom: 0,
                        opacityTo: 0.5,
                        stops: [0, 50, 100],
                        colorStops: [],
                      },
                    },
                    title: {
                      text: results[i].core_gateway_id,
                      align: 'left',
                      margin: 10,
                      offsetX: 0,
                      offsetY: 0,
                      floating: false,
                      style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238',
                      },
                    },
                    subtitle: {
                      text: results[i].description,
                      align: 'left',
                      margin: 10,
                      offsetX: 0,
                      offsetY: 20,
                      floating: false,
                      style: {
                        fontSize: '12px',
                        fontWeight: 'normal',
                        fontFamily: undefined,
                        color: '#9699a2',
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    xaxis: {
                      type: 'datetime',
                      labels: {
                        datetimeFormatter: {
                          year: 'yyyy',
                          month: "MMM 'yy",
                          day: 'dd MMM',
                          hour: 'HH:mm',
                        },
                      },
                    },
                    yaxis: [
                      {
                        title: {
                          text: '',
                        },
                      },
                      {
                        opposite: true,
                        title: {
                          text: '',
                        },
                      },
                    ],
                    tooltip: {
                      enabled: true,
                      x: {
                        show: true,
                        format: 'dd MMM HH:mm',
                      },
                    },
                  },

                  series: res.data.series,
                };
                this.dataset.push(set);

                //Once the final dataset is finished loading, then let's
                // turn off the loading image
                counter++;
                // console.log('the value of counter : ' + counter);
                // console.log('results.length : ' + results.length);

                if (results.length == counter) {
                  this.loading = false;
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .finally(() => {
          console.log('finally() called');
          // this.loading = false;
        })
        .catch((err) => {
          // this.raiseErrorAlert(err);
          console.log('err:');
          console.log(err);
        });
    },
  },
  created() {
    this.loadGraphs('3d');
    console.log('created() called');
  },
};
</script>
